<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row class="p-5">
          <v-col cols="12" xs="12" sm="6" lg="6">
            <TotalDevices />
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="6">
            <TotalClientes />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="6" lg="4">
            <TotalWaterDeices />
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="4">
            <TotalEnergyDevices />
          </v-col>
          <v-col cols="12" xs="12" sm="6" lg="4">
            <TotalGasDevices />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import BackendApi from "@/services/backend.service";

import TotalDevices from "./components/TotalDevices.vue";
import TotalClientes from "./components/TotalClients.vue";
import TotalWaterDeices from "./components/TotalWaterDeices.vue";
import TotalEnergyDevices from "./components/TotalEnergyDevices.vue";
import TotalGasDevices from "./components/TotalGasDevices.vue";

moment().format();

export default {
  components: {
    TotalDevices,
    TotalClientes,
    TotalWaterDeices,
    TotalEnergyDevices,
    TotalGasDevices,
  },
  data() {
    return {
      snackbar: false,
      dialogConfirm: false,
      dialogConfirmPassword: false,
      dialogConfirmLoginById: false,
      loadingLoginById: false,
      color: { hex: null },
      isBrandBlank: false,
      dialog: false,
      headers: [
        {
          text: "Id",
          value: "id_usuario",
        },
        { text: "Nombre", value: "name" },
        { text: "Email", value: "email" },
        { text: "RUC", value: "ruc" },
        { text: "Ult. Sesión", value: "last_session" },
        { text: "Acciones", value: "actions" },
      ],
      users: [],
      msg: {
        caConsumo: 9876543210,
        device: "CAFECAFE",
        alias: "Dispositivo De Medicion Numero Uno",
        ubicacion: "-12.76553733,-76.8755363",
        timestamp: 45677,
        pulsosA: 4095,
        pulsos: 4294967295,
        fraude: true,
        s_energia: true,
        status_ctlr: true,
        checkConnect: true,
        Status_batt: [2800, 3000, 4000],
      },
    };
  },
  computed: {
    isAdmin() {
      return (
        this.$cookies.get("isSuperAdmin") === "true" ||
        this.$cookies.get("isAdmin") === "true"
      );
    },
  },
  created() {
    this.getUsers();
  },
  mounted() {
    this.isAdminRouter();
  },
  methods: {
    isAdminRouter() {
      if (!this.isAdmin) {
        this.$router.push({ name: "Reportes Totales" });
      }
    },
    async getUsers() {
      const { data } = await BackendApi.get("/user/users");
      if (data.success) {
        this.users = data.data;
      }
    },
    getLastSession(item) {
      return moment(item.last_session).format("DD/MM/YYYY hh:mm");
    },
    openNewUser() {
      this.$refs.newUserComponent.open();
    },
    async deleteUser(item2) {
      const { data } = await BackendApi.delete("/user/user/" + item2.id);
      if (data.success) {
        this.dialogConfirm = false;
        this.getUsers();
      }
    },
    openUpdateUser(item) {
      this.$refs.newUserComponent.openUpdate(item);
      // this.dialogConfirm = true;
    },
    async changePassword(item) {
      const payload = {
        id_user: item.id,
      };
      const { data } = await BackendApi.post("/user/changePassword", payload);
      if (data.success) {
        this.$refs.dialogResetPassworh.open(
          data?.data?.email,
          data?.data?.password
        );
        this.dialogConfirmPassword = false;
        this.getUsers();
      }
    },
    async loginById(item) {
      this.loadingLoginById = true;
      const payload = {
        user_id: item.id,
      };
      const { data } = await BackendApi.post("/auth/loginById", payload);
      if (data.success) {
        this.$cookies.set("token", data.token);
        this.$cookies.set("user", data.user);
        this.$cookies.set("isAdmin", data.isAdmin);
        this.$cookies.set("isSuperAdmin", data.isSuperAdmin);
        this.$cookies.set("color", data.user.brand_blank.color);
        this.$cookies.set("footer", data.user.brand_blank.footer);
        this.$cookies.set("logo", data.user.brand_blank.logo);
        this.$cookies.set(
          "agua",
          data.devices.some((device) => device.name === "agua")
        );
        this.$cookies.set(
          "energia",
          data.devices.some((device) => device.name === "energía")
        );
        this.$cookies.set(
          "gas",
          data.devices.some((device) => device.name === "gas")
        );
        this.dialogConfirmLoginById = false;
        this.loadingLoginById = false;
        window.location.reload();
      } else {
        this.snackbar = true;
      }
    },
    onCreatedUser() {
      this.getUsers();
      this.$refs.newUserComponent.getUserN1();
    },
    onResetPassword() {
      this.$refs.dialogResetPassworh.close();
    },
  },
};
</script>
