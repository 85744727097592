<template>
  <v-card outlined>
    <v-card-title>TOTAL CLIENTE</v-card-title>
    <v-card-text>
      <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
      <v-list-item v-else>
        <v-list-item-content>
          <v-list-item-title
            >Total Administradores: {{ dataClients.admin }}</v-list-item-title
          >
          <v-list-item-title
            >Total Sub-Administradores (Nivel1):
            {{ dataClients.n1 }}</v-list-item-title
          >
          <v-list-item-title
            >Total Usuarios (Nivel2): {{ dataClients.n2 }}</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
import BackendApi from "@/services/backend.service";

export default {
  data() {
    return {
      isLoading: false,
      dataClients: {
        admin: 0,
        n1: 0,
        n2: 0,
      },
    };
  },
  mounted() {
    this.getDevices();
  },
  methods: {
    getDevices() {
      this.isLoading = true;
      BackendApi.get("/dashboard/totalClients")
        .then((response) => {
          if (response.data.success) {
            this.dataClients = response.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          
        });
    },
  },
};
</script>

<style></style>
