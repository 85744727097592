<template>
  <v-card outlined>
    <v-card-title>TOTAL DISPOSITIVOS GAS</v-card-title>
    <v-card-text>
      <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
      <v-list-item v-else>
        <v-list-item-content>
          <v-list-item-title
            >Total dispositivos Registrados:
            {{ dataDevices.totalGas }}</v-list-item-title
          >
          <v-list-item-title
            >Total dispositivos Operativos:
            {{ dataDevices.operativos }}</v-list-item-title
          >
          <v-list-item-title
            >Total dispositivos Inoperativos:
            {{ dataDevices.Inoperativos }}</v-list-item-title
          >
          <v-list-item-title
            >Total dispositivos con Alarma:
            {{ dataDevices.alerta }}</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
import BackendApi from "@/services/backend.service";

export default {
  data() {
    return {
      isLoading: false,
      dataDevices: {
        totalGas: 0,
        operativos: 0,
        Inoperativos: 0,
        alerta: 0,
      },
    };
  },
  mounted() {
    this.getDevices();
  },
  methods: {
    getDevices() {
      this.isLoading = true;
      BackendApi.get("/dashboard/totalGasDevices")
        .then((response) => {
          if (response.data.success) {
            this.dataDevices = response.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          
        });
    },
  },
};
</script>

<style></style>
